import React, { useEffect, useState } from "react";
import {
  PaymentRequestButtonElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import StatusMessages, { useMessages } from "./StatusMessages";
import axios from "axios";

const ApplePayCheckout: React.FC<any> = ({
  customerId,
  country,
  items,
  totalAmount,
  currency,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState<any>(null);
  const [messages, addMessage] = useMessages();

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }

    const pr = stripe.paymentRequest({
      country,
      currency,
      total: {
        label: "Product total",
        amount: totalAmount,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    // console.log("pr >>>>", pr);
    // Check the availability of the Payment Request API.
    pr.canMakePayment().then((result) => {
      if (result) {
        setPaymentRequest(pr);
      }
    });

    pr.on("paymentmethod", async (e) => {
      const { error: backendError, clientSecret } = await axios
         /* .post(
          "https://api-stripe-apple-pay.tividad.com/create-payment-intent",
          // "https://stripe-api.tividad.com/create-payment-intent",
          // "https://ecommerce-api.tividad.com/api/v1/stripe/create-invoice-intent",
          // "https://ecommerce-api.tividad.com/api/v1/stripe/create-payment-intent",
          {
            paymentMethodType: "card",
            currency: "aed",
          }
        )
         */
        .post(
            //  "https://api.storein.net/api/v1/stripe/create-invoice-intent",
             "https://api.winnerx.shop/api/v1/stripe/create-invoice-intent",
          {
            customerId,
            paymentMethodType: "card",
            currency,
            items,
          }
        )
     
        .then((r: any) => r.data);

      if (backendError) {
        addMessage(backendError.message);
        return;
      }

      addMessage("Client secret returned");

      // console.log("clientSecret >>>", clientSecret);

      const { error: stripeError, paymentIntent } =
        await stripe.confirmCardPayment(
          clientSecret,
          {
            payment_method: e.paymentMethod.id,
          },
          { handleActions: false }
        );

      // alert("paymentIntent.status >>>" + JSON.stringify(paymentIntent));
      // console.log("paymentIntent.status >>>", paymentIntent);
      if (stripeError) {
        // Show error to your customer (e.g., insufficient funds)
        addMessage(stripeError.message);
        e.complete("fail");
        return;
      }

      e.complete("success");

      // Check if the PaymentIntent requires any actions and if so let Stripe.js
      // handle the flow. If using an API version older than "2019-02-11" instead
      // instead check for: `paymentIntent.status === "requires_source_action"`.
      if (paymentIntent.status === "requires_action") {
        // Let Stripe.js handle the rest of the payment flow.
        let { error, paymentIntent } = await stripe.confirmCardPayment(
          clientSecret
        );
        if (error) {
          // The payment failed -- ask your customer for a new payment method.
          addMessage(error.message);
          return;
        }
        // addMessage(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);
      }

      // Show a success message to your customer
      // There's a risk of the customer closing the window before callback
      // execution. Set up a webhook or plugin to listen for the
      // payment_intent.succeeded event that handles any business critical
      // post-payment actions.
      addMessage(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);
    });
  }, [stripe, elements, addMessage]);

  return (
    <>
      <h1>Stripe Apple Pay</h1>

    
      {paymentRequest && (
        <PaymentRequestButtonElement options={{ paymentRequest }} />
      )}

      <StatusMessages messages={messages} />
 
    </>
  );
};

export default ApplePayCheckout;
