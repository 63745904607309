import React, { useEffect, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import StatusMessages, { useMessages } from "./StatusMessages";
import axios from "axios";
import ApplePayCheckout from "./ApplePayCheckout";
import GooglePay from "./GooglePay";

const customerId = "cus_Lur6fcjmuLDs3X";
const country = "AE";

const CheckOut: React.FC<any> = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [messages, addMessage] = useMessages();

  const [items, setItems] = useState<any>([]);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [currency, setCurrencty] = useState<string>("aed");
  const handleSubmit = async (e: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      addMessage("Stripe.js has not yet loaded.");
      return;
    }

    const { error: backendError, clientSecret } = await axios
      .post(
        // "https://api-stripe-apple-pay.tividad.com/create-payment-intent",
        // "https://api.storein.net/api/v1/stripe/create-invoice-intent",
        "https://api.winnerx.shop/api/v1/stripe/create-invoice-intent",
        {
          customerId,
          paymentMethodType: "card",
          currency,
          items,
        }
      )
      .then((r: any) => r.data);

    if (backendError) {
      addMessage(backendError.message);
      return;
    }

    addMessage("Client secret returned");

    const card: any = elements.getElement(CardElement);
    const { error: stripeError, paymentIntent } =
      await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card,
          billing_details: {
            name: "Auric Gavin",
            email: "auric.gavin@tividad.com",
            address: {
              line1: "Silicon Oasis",
              city: "Dubai",
              state: "Dubai",
              country: "AE",
              postal_code: "00000",
            },
          },
        },
      });

    if (stripeError) {
      // Show error to your customer (e.g., insufficient funds)
      addMessage(stripeError.message);
      ////// e.complete("fail");
      return;
    }

    //////e.complete("success");

    // Check if the PaymentIntent requires any actions and if so let Stripe.js
    // handle the flow. If using an API version older than "2019-02-11" instead
    // instead check for: `paymentIntent.status === "requires_source_action"`.
    if (paymentIntent.status === "requires_action") {
      // Let Stripe.js handle the rest of the payment flow.
      let { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret
      );
      if (error) {
        // The payment failed -- ask your customer for a new payment method.
        addMessage(error.message);
        return;
      }
      // addMessage(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);
    }

    // Show a success message to your customer
    // There's a risk of the customer closing the window before callback
    // execution. Set up a webhook or plugin to listen for the
    // payment_intent.succeeded event that handles any business critical
    // post-payment actions.
    addMessage(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.post(
        // "https://api.storein.net/api/v1/products/fetch-all"
        "https://api.winnerx.shop/api/v1/products/fetch-all"
      );

      let products: any = [];

      console.log("result >>>>", result);

      if (result?.data?.products) {
        result?.data?.products
          .filter((dat: any) => dat.unit_price < 10)
          .map(
            (
              {
                product_code,
                product_name,
                price_id,
                currency_code,
                unit_price,
              }: any,
              index: number
            ) => {
              products = [
                ...products,
                {
                  product_code,
                  name: product_name,
                  price_id,
                  currency: currency_code,
                  price: unit_price,
                  quantity: 1,
                },
              ];
            }
          );
      }

      console.log("prods >>>", products);
      const sum = products.reduce((accumulator: any, object: any) => {
        return (
          accumulator + (object.price ? parseFloat(object.price) : 0)
        );
      }, 0);

      setTotalAmount(sum);

      setItems(products);
    };

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, [setItems]);

  return (
    <>
      <h1>Check out</h1>

      <table id="products">
        <thead>
          <tr>
            <th>product_code</th>
            <th> product_name</th>
            <th> price_id</th>
            <th> quantity</th>
            <th> unitPrice</th>
          </tr>
        </thead>
        <tbody>
          {items &&
            items.length > 0 &&
            items.map(
              (
                { product_code, name, price_id, currency, price }: any,
                index: number
              ) => {
                return (
                  <tr key={index}>
                    <td>{product_code}</td>
                    <td> {name}</td>
                    <td> {price_id}</td>
                    <td> 1 </td>
                    <td>
                      {currency}
                      {price}
                    </td>
                  </tr>
                );
              }
            )}
        </tbody>
      </table>

      <br />
      <br />
      {console.log("totalAmount >>>>", totalAmount, items)}

      {totalAmount && items && items.length > 0 && (
        <>
          <ApplePayCheckout
            customerId={customerId}
            country={country}
            items={items}
            totalAmount={totalAmount * 100}
            currency={currency}
          />
          {/* <GooglePay
            customerId={customerId}
            country={country}
            items={items}
            totalAmount={totalAmount * 100}
            currency={currency}
          /> */}
        </>
      )}
      {console.log("items >>>>", items)}

      <form id="payment-form" onSubmit={handleSubmit}>
        <label htmlFor="card">Card</label>
        <CardElement id="card" />

        <button type="submit">
          Pay Total Amount of {currency.toUpperCase()}
          {totalAmount}
        </button>
      </form>
      <StatusMessages messages={messages} />
    </>
  );
};

export default CheckOut;
